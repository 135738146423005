<template>
  <v-app-bar color="primary" flat height="40" border="0">
    <template v-slot:prepend>
      <v-btn rounded="0" icon @click.stop="switchRail()">
        <v-tooltip
          activator="parent"
          location="bottom"
          :text="rail ? 'Expandir Menú' : 'Cerrar Menú'"
        />
        <v-icon>{{ rail ? "mdi-menu" : "mdi-menu-open" }}</v-icon>
      </v-btn>
      <v-btn rounded="0" icon :href="urlWeb" target="_blank">
        <v-tooltip activator="parent" location="bottom" text="Página Web" />
        <v-icon>mdi-web</v-icon>
      </v-btn>
      <v-btn rounded="0" icon :href="urlPortal" target="_blank">
        <v-tooltip
          activator="parent"
          location="bottom"
          text="Portal de Socios"
        />
        <v-icon>mdi-account-supervisor</v-icon>
      </v-btn>

      <v-btn rounded="0" icon to="/historial" :disabled="user.cambio_clave">
        <v-tooltip
          activator="parent"
          location="bottom"
          text="Historial de cambios"
        />
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>
    </template>
    <template v-slot:append>
      <v-btn rounded="0" icon :to="{ path: 'cuenta' }" id="cambio-clave">
        <v-icon>mdi-account-lock</v-icon>
      </v-btn>
      <v-tooltip
        activator="#cambio-clave"
        location="bottom"
        text="Cambio de clave"
      />
      <v-btn rounded="0" icon class="no-click">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>

      {{ user.name + " " + user.lastname }}
    </template>
  </v-app-bar>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapState(["rail", "user"]),
    urlPortal() {
      return process.env.VUE_APP_PORTAL_URL;
    },
    urlWeb() {
      return process.env.VUE_APP_WEB_URL;
    }
  },
  methods: {
    ...mapActions(["switchRail"])
  }
};
</script>
