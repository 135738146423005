<template>
  <v-app style="background: rgba(224, 242, 241, 0.4)">
    <drawer-app v-if="route != '/login' && route != '/'" />
    <system-bar v-if="route != '/login' && route != '/'" />
    <v-main class="mt-8">
      <router-view />
    </v-main>
    <footer-app />
  </v-app>
</template>

<script>
import DrawerApp from "./components/app/DrawerApp.vue";
import FooterApp from "./components/app/FooterApp.vue";
import SystemBar from "./components/app/SystemBar.vue";

export default {
  metaInfo: {
    title: "Administrador de contenido",
    titleTemplate: "%s | Islacoop",
    htmlAttrs: { lang: "es" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  },
  components: {
    "drawer-app": DrawerApp,
    "system-bar": SystemBar,
    "footer-app": FooterApp
  },
  computed: {
    theme_current() {
      return this.$vuetify.theme.current.colors;
    },
    route() {
      return this.$route.path;
    }
  }
};
</script>

<style>
/*p {
  margin-bottom: 16px !important;
}*/

.car-upload .el-upload {
  width: 100% !important;
}
.opciones .ql-picker-options {
  z-index: 9999 !important;
  position: absolute !important;
}
.edit-z {
  z-index: 1 !important;
}
.edit-bottom {
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 999 !important;
}
.edit-top {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  z-index: 999 !important;
}
.dotDivider {
  border-bottom: 5px dotted v-bind("theme_current.primaryDk1");
}
.text-trunc {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}
.text-trunc-3 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.edit-field .v-field__input {
  padding-top: 0px !important;
}
.test .ql-editor {
  min-height: 100px !important;
}
.text-preview .ql-editor {
  min-height: 0px !important;
  height: auto !important;
  overflow-y: clip !important;
}
.no-click {
  pointer-events: none;
}
.si-click {
  cursor: pointer !important;
}
.border-color {
  border-color: #34a693 !important;
  border-width: 0.3px !important;
}
.border-light {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}
.raleway-thin {
  font-family: "Raleway", sans-serif;
  font-weight: 100;
}
.raleway-light {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}
.raleway-regular {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
.raleway-medium {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}
.titulo {
  font-size: 1.6rem;
}

.titulo-seccion {
  font-size: 1.55rem;
}

.titulo-dialogo {
  font-size: 1.4rem;
}
.titulo-card {
  font-size: 1.2rem !important;
}
.switch-dense .v-switch--inset .v-switch__track {
  height: 15px !important;
  width: 35px !important;
}
.switch-dense .v-switch__thumb {
  background-color: white !important;
  height: 10px !important;
  width: 10px !important;
}
.v-switch--inset .v-switch__track {
  height: 22px !important;
  width: 45px !important;
}
.v-switch__track {
  opacity: 1 !important;
}
.v-switch__thumb {
  background-color: white !important;
  height: 17px !important;
  width: 17px !important;
}
.v-switch .v-label {
  color: black !important;
  opacity: 0.6 !important;
}
.no-line .v-field__outline {
  --v-field-border-width: 0px !important;
}
.no-line .v-field--variant-filled .v-field__outline::after {
  border-style: none !important;
}
.no-line .v-field__prepend-inner > .v-icon {
  opacity: 1 !important;
}

.rounded-border .v-field {
  border-radius: 25px !important;
  padding-inline-start: 24px !important;
  padding-inline-end: 24px !important;
}

.no-break {
  white-space: normal !important;
}
.no-input input {
  display: none !important;
  cursor: pointer !important;
}
.pointer .v-field__input {
  cursor: pointer !important;
}
.pointer .v-field__input input {
  cursor: pointer !important;
}
.icon {
  opacity: 100% !important;
}
.el-table .striped-class {
  --el-table-tr-bg-color: rgba(222, 247, 247, 0.6);
}
.el-table .cell {
  word-break: break-word !important;
  white-space: pre-line !important;
}

.el-table th .cell {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.menu-items .v-list-item__prepend > .v-list-item__spacer {
  width: 15px !important;
}
.menu-items .v-list-item__prepend > .v-icon {
  opacity: 1 !important;
}
.dense .el-table .el-table__row .el-table__cell {
  padding: 0px !important;
}
.news-item .v-list-item__content {
  align-self: baseline !important;
}
.dense-empty .el-table__empty-text {
  line-height: 0px !important;
}
.v-input__details {
  padding-top: 0px !important;
  min-height: 0px !important;
  padding-inline-end: 0px !important;
}
.v-messages__message {
  margin-top: 6px !important;
  margin-bottom: 3px !important;
}
.htmltext .v-messages__message {
  margin-top: -18px !important;
  margin-bottom: 3px !important;
}
.eliminarConfirm {
  background-color: rgba(255, 0, 0, 0.612) !important;
  border-color: rgba(255, 0, 0, 0.612) !important;
}
.warningConfirm {
  background-color: rgba(216, 177, 0, 0.616) !important;
  border-color: rgba(213, 174, 0, 0.612) !important;
}
.space-text .el-skeleton__paragraph {
  margin-top: 2px !important;
}
.grid-skelenton .el-skeleton {
  display: grid !important;
}
.v-list-subheader__text {
  white-space: normal !important;
}
.v-alert__close {
  margin-inline-start: 10px !important;
}
.all-sub .v-list-item--one-line .v-list-item-subtitle {
  -webkit-line-clamp: 2 !important;
}
.MessagetextClass {
  white-space: break-spaces;
  z-index: 10000000 !important;
}
.el-empty {
  --el-empty-description-margin-top: 10px !important;
}
.el-button.is-disabled:hover {
  cursor: auto !important;
  pointer-events: auto !important;
}
.white-link a {
  color: white !important;
}

.qlbt-operation-menu {
  z-index: 10000 !important;
  max-height: 160px;
  width: 300px;
  overflow-y: scroll !important;
}

.text-color > p {
  color: black !important;
}
.text-color > p > span {
  color: rgb(66, 66, 66) !important;
}

.switch-cursor .v-selection-control__input input,
.v-switch__track {
  cursor: default !important;
}

.el-table .caret-wrapper {
  width: 18px !important;
}

.ql-container {
  font-family: "Roboto", sans-serif !important;
}

.ql-portal .ql-container p {
  margin-bottom: 16px !important;
}
.ql-container li {
  margin-bottom: 10px !important;
}
.v-list-item--nav .v-list-item-title {
  font-weight: 400 !important;
}

.el-tabs__item {
  font-weight: 400 !important;
}
.v-btn {
  font-weight: 400 !important;
}

.app-disabled .v-btn--disabled.v-btn--variant-elevated,
.app-disabled .v-btn--disabled.v-btn--variant-flat {
  opacity: 0.5 !important;
  color: white !important;
  background: red !important;
}

.version-tooltip {
  font-size: 12px !important;
  color: black !important;
  background-color: white !important;
  padding: 2px 5px !important;
}
</style>
