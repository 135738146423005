import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Vuetify
import "vuetify/styles";
import vuetify from "./plugins/vuetify";

//Element
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

//Configuraciones de Vuetify y Element
import "./plugins";

//Axios
import api from "./config/axios.js";
import axios from "axios";

//Maska
import { vMaska } from "maska";

//html purificado
import DOMPurify from "dompurify";

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
  }
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(ElementPlus)
  .directive("maska", (el, binding) => {
    if (el.querySelector("input").value) vMaska(el, binding);
  })
  .directive("sane-html", (el, binding) => {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  });

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$api = api;

app.mount("#app");
