<template>
  <v-navigation-drawer
    v-model="drawer"
    :rail="rail"
    permanent
    width="225"
    elevation="4"
    class="menu-items"
  >
    <v-list-item nav>
      <v-img
        v-if="!rail"
        width="150"
        height="50"
        class="mx-auto"
        :src="require('@/assets/logos/logo.png')"
      >
      </v-img>
      <v-img
        v-else
        height="50"
        class="mx-auto"
        :src="require('@/assets/logos/logo-small.png')"
      />
    </v-list-item>

    <v-divider></v-divider>
    <v-list
      density="compact"
      nav
      color="primary"
      lines="one"
      exact
      class="text-nav"
      :disabled="user.cambio_clave"
    >
      <template v-for="(item, i) in items" :key="item.to">
        <v-list-subheader
          v-if="item.pretitle"
          style="padding-inline-end: 0px !important"
        >
          {{ rail ? item.pretitleshort : item.pretitle }}
        </v-list-subheader>
        <v-list-item :to="item.to" exact>
          <template v-slot:prepend>
            <v-icon
              :icon="item.icon"
              color="primary-accent"
              :id="'iconMenu-' + i"
            ></v-icon>
            <v-tooltip
              v-if="rail"
              :activator="'#iconMenu-' + i"
              location="right"
              :text="item.title"
            />
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="pa-2" v-if="!rail">
        <v-btn color="error" block @click="logout(false)">
          <v-icon start>mdi-logout</v-icon>
          Cerrar Sesión
        </v-btn>
      </div>
      <div v-else>
        <v-list density="compact" nav color="primary" lines="one">
          <v-list-item @click="logout(false)">
            <template v-slot:prepend>
              <v-icon
                icon="mdi-logout"
                color="error"
                id="iconMenu-logout"
              ></v-icon>
              <v-tooltip
                activator="#iconMenu-logout"
                location="right"
                text="Cerrar Sesión"
              />
            </template>
          </v-list-item>
        </v-list>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import menuItem from "@/assets/json/menu.json";

export default {
  data() {
    return {
      drawer: true
    };
  },
  methods: {
    ...mapActions(["switchRail", "logout"]),
    acceso(require) {
      if (Object.keys(this.user).length === 0) {
        return false;
      } else if (!require) return true;
      else return this.user.permissions.includes(require);
    }
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getRail"]),
    items() {
      return menuItem.filter(
        (item) => !item.hide_lat && this.acceso(item.require)
      );
    },
    rail: {
      get() {
        return this.getRail;
      },
      set(state) {
        this.switchRail(state);
        return state;
      }
    }
  },
  mounted() {}
};
</script>
