import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import es from "vuetify/lib/locale/es";
//import { mdi } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";
import colors from "vuetify/lib/util/colors";

const myTheme = {
  dark: false,
  colors: {
    background: "#DAD4C6",
    surface: "#FFFFFF",
    primary: "#003E44",
    "primary-dark": "#256c74",
    "primary-accent": colors.cyan.darken3,
    secondary: "#24cad4",
    accent: "#EA3323",
    error: "#FF5252",
    info: "#2196F3",
    success: "#13CE66",
    warning: "#ffc107",
    gris: "#757575",
    "gris-claro": "#E0E0E0",
    primaryDk1: colors.teal.darken1,
    greenDivider: colors.teal.darken1,
    greenBg: colors.teal.lighten5
  }
};

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "myTheme",
    themes: {
      myTheme
    }
  },
  locale: {
    locale: "es",
    fallback: "es",
    messages: { es }
  },
  icons: {
    defaultSet: "mdi"
    /*aliases,
    sets: {
      mdi,
    }*/
  }
});
