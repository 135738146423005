<template>
  <v-footer color="primary" padless inset app class="pa-0">
    <v-row justify="space-around" dense>
      <v-col class="pb-1 text-center text-subtitle-2 bg-primary" cols="12">
        ©
        <strong class="white-link">
          <a
            class="white--text text-decoration-none"
            href="https://www.maximizaerp.cl/"
            target="_blank"
          >
            Maximiza
          </a>
        </strong>
        {{ new Date().getFullYear() }}
        <v-btn
          variant="plain"
          icon
          density="compact"
          size="small"
          position="absolute"
          location="end"
        >
          <v-icon color="primary">mdi-help-box</v-icon>
          <v-tooltip
            activator="parent"
            location="top"
            text="12-02-2024"
            class="text-black"
            content-class="version-tooltip"
          >
          </v-tooltip>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
//import { mapActions } from "vuex";

export default {
  data: () => ({
    copyText: "Copiar",
    links: [
      {
        name: "contacto@islacoop.cl",
        icon: "mdi-email",
        text: "contacto@islacoop.cl"
      },
      {
        icon: "mdi-map-marker",
        name: "Av. Santelices 427, Isla De Maipo",
        link: "https://goo.gl/maps/9UGHyPkC1RjD4c6g9",
        span: "Ver en Google Maps"
      },
      { name: "+569 3944 5824", icon: "mdi-whatsapp", text: "+56939445824" },
      { name: "+562 2819 2620", icon: "mdi-phone", text: "+56228192620" }
    ]
  }),
  methods: {
    // ...mapActions(["switchDrawer"]),
    copiarTexto(value) {
      navigator.clipboard.writeText(value);
      this.copyText = "Copiado !";
    }
  },
  computed: {
    /* mobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    }*/
  },
  mounted() {
    /*if (this.mobile) {
      this.switchDrawer(false);
    }*/
  }
};
</script>
<style scoped lang="css">
.texto-footer {
  width: min-content;
  white-space: normal;
  flex: auto;
}
</style>
