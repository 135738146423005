import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  //Rutas con autenticación
  {
    path: "/inicio",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/historial",
    name: "historial",
    component: () => import("../views/HistorialView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/cuenta",
    name: "cuenta",
    component: () => import("../views/CuentaView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: () => import("../views/UsuariosView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "users"
    }
  },
  {
    path: "/ajustes-portal",
    name: "ajustes-portal",
    component: () => import("../views/portal/PortalAjustesView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "portal-settings"
    }
  },
  {
    path: "/menu-portal",
    name: "menu-portal",
    component: () => import("../views/portal/MenuPortalView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "portal-menu"
    }
  },
  {
    path: "/informativos",
    name: "informativos",
    component: () => import("../views/portal/InformativosView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "informatives"
    }
  },
  {
    path: "/publicidades",
    name: "publicidades",
    component: () => import("../views/portal/PublicidadView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "advertisements"
    }
  },

  {
    path: "/descarga-documentos",
    name: "descarga-documentos",
    component: () => import("../views/portal/DocumentosView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "docs"
    }
  },

  {
    path: "/textos-portal",
    name: "textos-portal",
    component: () => import("../views/portal/TextoInformacionesView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "portal-text"
    }
  },

  {
    path: "/menu-web",
    name: "menu-web",
    component: () => import("../views/web/MenuWebView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "web-menu"
    }
  },
  {
    path: "/footer-web",
    name: "footer-web",
    component: () => import("../views/web/FooterWebView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "footer-menu"
    }
  },
  {
    path: "/contenido-web",
    name: "contenido-web",
    component: () => import("../views/web/ContenidoView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "content-web"
    }
  },
  {
    path: "/documentos-web",
    name: "documentos-web",
    component: () => import("../views/web/DocumentosPublicosView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "web-docs"
    }
  },
  {
    path: "/formularios-web",
    name: "formularios-web",
    component: () => import("../views/web/FormulariosView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "web-forms"
    }
  },

  {
    path: "/",
    redirect: "/inicio"
  },

  //Rutas sin autenticación
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue")
  },
  {
    //Para capturar rutas sin registrar
    path: "/:catchAll(.*)",
    redirect: "/login"
  }
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active"
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.token) {
      await store.dispatch("setRefreshToken");
    }
    if (store.state.token) {
      if (store.state.user.cambio_clave) {
        if (to.name === "cuenta") {
          next();
        } else {
          next({ name: "cuenta" });
        }
      } else {
        if (to.matched.some((record) => record.meta.requiresPermission)) {
          if (
            store.state.user.permissions.includes(to.meta.requiresPermission)
          ) {
            next();
          } else {
            next({ name: "home" });
          }
        } else {
          next();
        }
      }
    } else {
      next({ name: "login" });
    }
  } else {
    if (!store.state.token) {
      await store.dispatch("setRefreshToken");
    }
    if (store.state.token) {
      next({ name: "home" });
    } else {
      next();
    }
  }
});

export default router;
